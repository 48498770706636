import { render, staticRenderFns } from "./UserListSettings.vue?vue&type=template&id=225b3c7c&scoped=true&"
import script from "./UserListSettings.vue?vue&type=script&lang=js&"
export * from "./UserListSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "225b3c7c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VCheckbox,VIcon,VMenu})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3729708968/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('225b3c7c')) {
      api.createRecord('225b3c7c', component.options)
    } else {
      api.reload('225b3c7c', component.options)
    }
    module.hot.accept("./UserListSettings.vue?vue&type=template&id=225b3c7c&scoped=true&", function () {
      api.rerender('225b3c7c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/UserListSettings.vue"
export default component.exports