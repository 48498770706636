import { render, staticRenderFns } from "./Me.vue?vue&type=template&id=eed1020a&scoped=true&"
import script from "./Me.vue?vue&type=script&lang=js&"
export * from "./Me.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eed1020a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3729708968/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('eed1020a')) {
      api.createRecord('eed1020a', component.options)
    } else {
      api.reload('eed1020a', component.options)
    }
    module.hot.accept("./Me.vue?vue&type=template&id=eed1020a&scoped=true&", function () {
      api.rerender('eed1020a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/account/Me.vue"
export default component.exports