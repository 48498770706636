import { render, staticRenderFns } from "./DividerConfig.vue?vue&type=template&id=3ccebcf0&scoped=true&"
import script from "./DividerConfig.vue?vue&type=script&lang=js&"
export * from "./DividerConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ccebcf0",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VBtn,VColorPicker,VMenu,VSlider})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3729708968/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3ccebcf0')) {
      api.createRecord('3ccebcf0', component.options)
    } else {
      api.reload('3ccebcf0', component.options)
    }
    module.hot.accept("./DividerConfig.vue?vue&type=template&id=3ccebcf0&scoped=true&", function () {
      api.rerender('3ccebcf0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/templates/invoices/config/DividerConfig.vue"
export default component.exports