import { render, staticRenderFns } from "./Subtasks.vue?vue&type=template&id=10d435e2&scoped=true&"
import script from "./Subtasks.vue?vue&type=script&lang=js&"
export * from "./Subtasks.vue?vue&type=script&lang=js&"
import style0 from "./Subtasks.vue?vue&type=style&index=0&id=10d435e2&scoped=true&lang=scss&"
import style1 from "./Subtasks.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10d435e2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VDataTable,VIcon,VMenu})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3729708968/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10d435e2')) {
      api.createRecord('10d435e2', component.options)
    } else {
      api.reload('10d435e2', component.options)
    }
    module.hot.accept("./Subtasks.vue?vue&type=template&id=10d435e2&scoped=true&", function () {
      api.rerender('10d435e2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/projects/deliverable/Subtasks.vue"
export default component.exports