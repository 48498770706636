import { render, staticRenderFns } from "./Workflow.vue?vue&type=template&id=21122e9e&scoped=true&"
import script from "./Workflow.vue?vue&type=script&lang=js&"
export * from "./Workflow.vue?vue&type=script&lang=js&"
import style0 from "./Workflow.vue?vue&type=style&index=0&id=21122e9e&scoped=true&lang=scss&"
import style1 from "./Workflow.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21122e9e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3729708968/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('21122e9e')) {
      api.createRecord('21122e9e', component.options)
    } else {
      api.reload('21122e9e', component.options)
    }
    module.hot.accept("./Workflow.vue?vue&type=template&id=21122e9e&scoped=true&", function () {
      api.rerender('21122e9e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/workflow/Workflow.vue"
export default component.exports