import { render, staticRenderFns } from "./Insights.vue?vue&type=template&id=42d4c9cc&"
import script from "./Insights.vue?vue&type=script&lang=js&"
export * from "./Insights.vue?vue&type=script&lang=js&"
import style0 from "./Insights.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3729708968/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('42d4c9cc')) {
      api.createRecord('42d4c9cc', component.options)
    } else {
      api.reload('42d4c9cc', component.options)
    }
    module.hot.accept("./Insights.vue?vue&type=template&id=42d4c9cc&", function () {
      api.rerender('42d4c9cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/home/Insights.vue"
export default component.exports